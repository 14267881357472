/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import cookie from "react-cookies";
import "react-datepicker/dist/react-datepicker.css";
import { apiUrl, adminlimit } from "../../Helpers/Config";
import { GET_LISTDATA } from "../../../actions";
import Header from "../Layout/Header";
import Topmenu from "../Layout/Topmenu";
import Footer from "../Layout/Footer";
import Pagenation from "../Layout/Pagenation";
var module = "clientpanel/reports/";
var moduleName = "Analytics Report";
const clickType = [
  { name: "Click Count", key: "clickCount" },
  { name: "Signin Count", key: "signinCount" },
  { name: "Buy Count", key: "buyCount" },
];
class List extends Component {
  constructor(props) {
    super(props);
    var companyID = cookie.load("companyID");
    this.state = {
      companyID: companyID,
      path: this.props.match.path,
      totalRecords: 0,
      totalPage: 0,
      currentPage: 1,
      listdata: [],
      socialanalytics: [],
      loading: true,
      user: "",
      searchkeywords: "",
    };
    this.handleChangeText = this.handleChangeText.bind(this);
  }
  componentDidMount() {
    this.loadList(1);
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.listdata !== this.state.listdata) {
      this.setState({
        listdata: nextProps.listdata,
        socialanalytics: nextProps.socialanalytics,
        loading: false,
        totalRecords: nextProps.totalRecords,
        totalPage: nextProps.totalPages,
      });
    }
  }

  sateValChange = (field, value) => {
    if (field === "page") {
      this.setState(
        {
          loading: true,
          currentPage: value,
        },
        function () {
          this.loadList(value);
        }
      );
    }
  };
  handleChangeText(event) {
    var name = event.target.name;
    var value = event.target.value;
    this.setState({ [name]: value });
  }

  searchList() {
    this.setState({ loading: true }, function () {
      this.loadList(1);
    });
  }
  loadList(offset) {
    var addParams = "";

    if (this.state.searchkeywords !== "") {
      addParams += "&name=" + this.state.searchkeywords;
    }
    var params = {
      params:
        "limit=" +
        adminlimit +
        "&offset=" +
        offset +
        "&company_id=" +
        this.state.companyID +
        addParams,
      url: apiUrl + module + "analytics",
      authType: "client",
    };
    this.props.getListData(params);
  }
  resetSearch() {
    this.setState(
      {
        loading: true,
        name: "",
        searchkeywords: "",
      },
      function () {
        this.loadList(1);
      }
    );
  }

  render() {
    return (
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <Header {...this.props} currentPage={"analytics"} />
          <div className="layout-page">
            <Topmenu />

            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                <div className="row mb-3">
                  <div className="col-lg-10 col-md-6">
                    <h4 className="fw-bold">{moduleName}</h4>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-md-3">
                    <div className="form-floating form-floating-outline mb-4">
                      <input
                        type="text"
                        className="form-control"
                        name="searchkeywords"
                        onChange={this.handleChangeText}
                        placeholder="Search Keywords"
                        value={this.state.searchkeywords}
                      />
                      <label htmlFor="name">Search</label>
                    </div>
                  </div>
                  <div className="col-md-4 mt-2">
                    <button
                      type="button"
                      className="btn btn-primary me-sm-3 me-1 waves-effect waves-light"
                      onClick={this.searchList.bind(this)}
                    >
                      Search
                    </button>
                    <button
                      type="reset"
                      className="btn btn-label-secondary waves-effect me-sm-3 me-1"
                      onClick={this.resetSearch.bind(this)}
                    >
                      Reset
                    </button>
                  </div>
                </div>
                <div className="card">
                  <div className="table-responsive text-nowrap p-1 mt-4">
                    <table className="table">
                      <thead>
                        <tr>
                          <th rowSpan={2} valign="middle">
                            Product Name
                          </th>
                          {Object.keys(this.state.socialanalytics).length > 0 &&
                             Object.keys(this.state.socialanalytics).map((item, index) => {
                              var socialanalytics = this.state.socialanalytics[item];
                              return (
                                <th
                                  key={index}
                                  colSpan={3}
                                  className="middlealign"
                                >
                                  {socialanalytics}
                                </th>
                              );
                            })}
                        </tr>
                        <tr>
                          {Object.keys(this.state.socialanalytics).length > 0 &&
                            Object.keys(this.state.socialanalytics).map(() => {
                              return clickType.map((citem, index) => {
                                return <th key={index}>{citem.name}</th>;
                              });
                            })}
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.loading === true ? (
                          <tr>
                            <td colSpan={6} align="center">
                              <div
                                className="spinner-border spinner-border-lg text-primary"
                                role="status"
                              >
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </div>
                            </td>
                          </tr>
                        ) : this.state.listdata.length > 0 ? (
                          this.state.listdata.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td>
                                  <strong>{item.name}</strong>
                                </td>
                                {Object.keys(this.state.socialanalytics).length > 0 && Object.keys(this.state.socialanalytics).map(
                                  (aitem, aindex) => {
                                    var socialanalytics = this.state.socialanalytics[aitem];
                                    var social = item?.[socialanalytics] || "";
                                    return clickType.map((citem, index) => {
                                      var counts = social?.[citem.key] || "0";
                                      return <td key={index}>{counts} </td>;
                                    });
                                  }
                                )}
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td className="text-center" colSpan={8}>
                              No {moduleName} Found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <Pagenation
                    params={{
                      totalRecords: this.state.totalRecords,
                      totalPage: this.state.totalPage,
                      currentPage: this.state.currentPage,
                    }}
                    sateValChange={this.sateValChange}
                  />
                </div>
              </div>

              <Footer />
            </div>
          </div>
        </div>

        <div className="layout-overlay layout-menu-toggle"></div>

        <div className="drag-target"></div>
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  var listdata = Array();
  var listdataStatus = "";
  var totalPages = 0;
  var totalRecords = 0;
  var socialanalytics = [];
  if (Object.keys(state.listdata).length > 0) {
    listdataStatus = state.listdata[0].status;
    if (state.listdata[0].status === "ok") {
      listdata = state.listdata[0].result;
      totalPages = state.listdata[0].totalPages;
      totalRecords = state.listdata[0].totalRecords;
      socialanalytics = state.listdata[0].socialanalytics;
    }
  }
  return {
    listdata: listdata,
    totalPages: totalPages,
    totalRecords: totalRecords,
    socialanalytics: socialanalytics,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getListData: (datas) => {
      dispatch({ type: GET_LISTDATA, datas });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(List);
