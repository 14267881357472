import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Select from "react-select";
import $ from "jquery";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import update from "immutability-helper";
import { validated } from "react-custom-validation";
import Switch from "react-switch";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { format } from "date-fns";
import { GET_FORMPOST, GET_DETAILDATA } from "../../../actions";
import { apiUrl, clientheaderconfig, frontURL } from "../../Helpers/Config";
import {
  showLoader,
  hideLoader,
  showAlert,
  userID,
  clientID,
  CompanyID,
  isEmpty,
  isSingleSelect,
  isValidPrice,
  isMultiSelect,
  uploadFile,
  removeFile,
  generateSlug,
} from "../../Helpers/SettingHelper";
import PageLoader from "../../Helpers/PageLoader";
import Header from "../Layout/Header";
import Topmenu from "../Layout/Topmenu";
import Footer from "../Layout/Footer";
import Editor from "../Layout/Editor";
var module = "clientpanel/paintbasedproducts/";
var moduleName = "Product";
var modulePath = "/clientpanel/sell-vouchers";
const defaultTerms =
  "<ol><li>Voucher is valid for retail store redemption.</li><li>The voucher will be valid till consumed.</li> <li>Once sold, vouchers are non-refundable and cannot be exchanged.</li><li>Voucher is not transferrable, cash redeemable and not replaceable.</li><li>Voucher is to be redeemed at only the participating outlets and presented to outlet staff before ordering.</li><li>Voucher is not to be used in conjunction with other promotions, discounts, offers, vouchers and privileges.</li><li>All vouchers can be used multiple times in full or in partial.</li><li>Brand reserves the right to amend the terms & conditions as necessary, without prior notice.</li><li>Please show this E-Voucher on your handheld devices upon payment. You need not print this E-Voucher for redemption.</li></ol>";
class Form extends Component {
  constructor(props) {
    super(props);
    var editID = "";
    if (this.props.match.path === "/clientpanel/sell-vouchers/edit/:EditID") {
      editID = this.props.match.params.EditID;
    }
    const ProName = this.props.location.state?.title || "";
    const ProDescription = this.props.location.state?.description || "";
    this.state = {
      editID: editID,
      pageloading: false,
      clientdata: {
        assign_outlet: [],
        company_catid: "",
        product_name: ProName,
        alias_name: "",
        sku: "",
        slug: "",
        voucher_type: "",
        voucher_credit_amount: "",
        voucher_bonus_amount: "",
        short_description: "",
        long_description: ProDescription,
        termsandconditions: defaultTerms,
        price: "",
        product_cost: "",
        status: [],
        voucher_expity_date: "",
        thumbnail: "",
        buy_refer: false,
        influencer_commission: "",
        applyFreeVoucher: false,
        recomentedVoucher: false,
        freevoucherQuantity: "",
        product_sequence: "",
        socialanalytics: [],
        productSocialanalytics: [""],
        productWelcomeVoucher:false,
        giftProduct:"",
        product_hide:false,
        action: "add",
      },
      btndisabled: false,
      loading: true,
      formpost: [],
      companyDetail: [],
      outletList: [],
      productTypeList: [],
      companyCatList: [],
      settingsDetails: [],
      giftProductList:[],
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.loadSettings();
    this.loadOutlet();
    this.loadproductTypeList();
    this.comapanyCatlist();
    this.getsocialSettings();
    this.loadGiftProduct();

    if (this.state.editID !== "") {
      var params = {
        params: "company_id=" + CompanyID() + "&detail_id=" + this.state.editID,
        url: apiUrl + module + "details",
        type: "client",
      };
      this.setState({ pageloading: true });
      this.props.getDetailData(params);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.state.formpost !== nextProps.formpost &&
      this.props.formpost !== nextProps.formpost
    ) {
      if (nextProps.formpost.length > 0) {
        hideLoader("submit_frm", "class");
        var errMsg =
          nextProps.formpost[0].form_error !== ""
            ? nextProps.formpost[0].form_error
            : nextProps.formpost[0].message;
        if (nextProps.formpost[0].status === "success") {
          showAlert("Success", errMsg, "success", "No");
          var history = this.props.history;
          setTimeout(function () {
            history.push(modulePath);
          }, 1000);
        } else {
          showAlert("Error", errMsg, "error", "No");
        }
      }
    }
    if (
      this.state.companyDetail !== nextProps.detaildata &&
      this.state.editID !== ""
    ) {
      this.setState({ companyDetail: nextProps.detaildata }, function () {
        if (nextProps.detaildata[0].status === "ok") {
          var result = nextProps.detaildata[0].result;
          var product_status =
            result.product_status === "A" ? "Active" : "In Active";
          var status =
            result.product_status !== "" && result.product_status !== null
              ? {
                  label: product_status,
                  value: result.product_status,
                }
              : "";
          var voucher_type =
            result.product_voucher === "F" ? "Product Voucher" : "Cash Voucher";
          var vouchertype =
            result.product_voucher !== "" && result.product_voucher !== null
              ? {
                  label: voucher_type,
                  value: result.product_voucher,
                }
              : "";
          var productSocialanalytics =
            result.productSocialanalytics !== "" &&
            result.productSocialanalytics !== null
              ? JSON.parse(result.productSocialanalytics)
              : [""];
          var clientupdatedata = {
            assign_outlet: result.outlet.length > 0 ? result.outlet : [],
            company_catid: result.product_company_catid,
            product_name: result.product_name,
            alias_name: result.product_alias,
            sku: result.product_sku,
            voucher_type: vouchertype,
            voucher_credit_amount: result.product_voucher_credit_amount,
            voucher_bonus_amount: result.product_voucher_bonus_amount,
            short_description: result.product_short_description,
            long_description: result.product_long_description,
            termsandconditions:
              result.product_termsandconditions !== null
                ? result.product_termsandconditions
                : "",
            price: result.product_price,
            product_cost: result.product_cost,
            voucher_expity_date:
              result.product_voucher_expiry_date !== "" &&
              result.product_voucher_expiry_date !== null &&
              result.product_voucher_expiry_date !== "0000-00-00"
                ? new Date(result.product_voucher_expiry_date)
                : "",
            thumbnail:
              result.product_thumbnail !== "" &&
              result.product_thumbnail !== null
                ? result.product_thumbnail
                : "",
            buy_refer: result.product_buy_refer === "1" ? true : false,
            applyFreeVoucher: result.productFree === "1" ? true : false,
            recomentedVoucher:
              result.recomentedVoucher === "Yes" ? true : false,
            freevoucherQuantity: result.productFreeQuantity,
            product_sequence: result.product_sequence,
            influencer_commission: result.product_influencer_commission,
            slug: result.product_slug,
            socialanalytics: result?.socialanalytics,
            productSocialanalytics:
              productSocialanalytics.length > 0 ? productSocialanalytics : [""],
            productWelcomeVoucher:result.productWelcomeVoucher==="1"?true:false,
            giftProduct:result?.giftProduct || '',
            product_hide:result.product_hide==="1"?true:false,
            status: status,
            action: "edit",
          };
          this.setState({ clientdata: clientupdatedata, pageloading: false });
        } else {
          this.setState({ pageloading: false });
          this.props.history.push(modulePath);
          showAlert("Error", "Invalid Product", "error");
        }
      });
    }
  }

  loadSettings() {
    var urlShringTxt =
      apiUrl + "clientpanel/settings/getSettings?company_id=" + CompanyID();
    axios.get(urlShringTxt, clientheaderconfig).then((res) => {
      if (res.data.status === "ok") {
        this.setState({ settingsDetails: res.data.result });
      }
    });
  }

  loadOutlet() {
    var urlShringTxt =
      apiUrl + "clientpanel/outlets/dropdownlist?company_id=" + CompanyID();
    axios.get(urlShringTxt, clientheaderconfig).then((res) => {
      if (res.data.status === "ok") {
        this.setState({ outletList: res.data.result });
      }
    });
  }

  comapanyCatlist() {
    var urlShringTxt =
      apiUrl +
      "clientpanel/paintbasedproducts/comapany_catlist?company_id=" +
      CompanyID();
    axios.get(urlShringTxt, clientheaderconfig).then((res) => {
      if (res.data.status === "ok") {
        this.setState({ companyCatList: res.data.result });
      }
    });
  }

  loadproductTypeList() {
    var urlShringTxt =
      apiUrl + module + "productType?company_id=" + CompanyID();
    axios.get(urlShringTxt, clientheaderconfig).then((res) => {
      if (res.data.status === "ok") {
        this.setState({ productTypeList: res.data.result });
      }
    });
  }

  getsocialSettings() {
    var urlShringTxt =
      apiUrl +
      "clientpanel/paintbasedproducts/getsocialSettings?company_id=" +
      CompanyID();
    axios.get(urlShringTxt, clientheaderconfig).then((res) => {
      if (res.data.status === "ok") {
        this.setState(
          update(this.state, {
            clientdata: { socialanalytics: { $set: res.data.result } },
          })
        );
      }
    });
  }

  loadGiftProduct() {
    var urlShringTxt = apiUrl + module + "dropdownlist?company_id=" + CompanyID()+'&product_type=7';
    if(this.state.editID!=="") {
      urlShringTxt+=`&ignoreProdduct=${this.state.editID}`
    }
    axios.get(urlShringTxt, clientheaderconfig).then((res) => {
      if (res.data.status === "ok") {
        var result = res.data.result;
        var giftProductList = []
        result.map((item)=> {
          giftProductList.push({label:item.product_name, value:item.product_primary_id});
          return "";
        })
        this.setState({ giftProductList: giftProductList });
      }
    });
  }

  

  sateValChange = (field, value) => {
    this.setState({ btndisabled: value });
  };

  handleChange(checked, name) {
    this.setState({ checked });
  }

  /* signin - start*/
  fieldChange = (field, value) => {
    this.setState(
      update(this.state, { clientdata: { [field]: { $set: value } } }),
      function () {
        if (field === "assign_outlet") {
        }
      }
    );
  };

  handleSubmit = () => {
    showLoader("submit_frm", "class");
    var postData = this.state.clientdata;
    var socialanalytics = [];
    if (postData.productSocialanalytics !== "") {
      postData.productSocialanalytics.map((item) => {
        if (item !== "") {
          socialanalytics.push(item);
        }
        return "";
      });
    }
    var assign_outlet = [];
    if (postData.assign_outlet.length > 0) {
      postData.assign_outlet.map((item) => {
        assign_outlet.push(item.value);
        return "";
      });
    }
    var postObject = {
      product_type: "7",
      product_name: postData.product_name,
      shop_type: 1,
      alias_name: postData.alias_name,
      sku: postData.sku,
      short_description: postData.short_description,
      long_description: postData.long_description,
      termsandconditions: postData.termsandconditions,
      voucher_type: postData.voucher_type?.value || "",
      voucher_credit_amount: postData.voucher_credit_amount,
      voucher_bonus_amount: postData.voucher_bonus_amount,
      status:
        Object.keys(postData.status).length > 0 ? postData.status.value : "A",
      price: postData.price,
      product_cost: postData.product_cost,
      assign_outlet: assign_outlet.length > 0 ? assign_outlet.join(",") : "",
      company_catid:
        Object.keys(postData.company_catid).length > 0
          ? postData.company_catid.value
          : "",
      voucher_expity_date:
        postData.voucher_expity_date !== ""
          ? format(postData.voucher_expity_date, "yyyy-MM-dd")
          : "",
      thumbnail: postData.thumbnail,
      buy_refer: postData.buy_refer === true ? "1" : "0",
      applyFreeVoucher: postData.applyFreeVoucher === true ? "1" : "0",
      recomentedVoucher: postData.recomentedVoucher === true ? "Yes" : "No",
      freevoucherQuantity: postData.freevoucherQuantity,
      product_sequence: postData.product_sequence,
      influencer_commission: postData.influencer_commission,
      productSocialanalytics:
        socialanalytics !== "" ? JSON.stringify(socialanalytics) : "",
      productWelcomeVoucher:postData.productWelcomeVoucher===true?"1":'',
      product_hide:postData.product_hide===true?"1":'',      
      giftProduct:postData.giftProduct?.value || '',
      loginID: userID(),
      company_admin_id: clientID(),
      company_id: CompanyID(),
      action: postData.action,
    };

    var post_url = module + "add";
    if (postData.action === "edit" && this.state.editID !== "") {
      postObject["edit_id"] = this.state.editID;
      post_url = module + "update";
    }
    this.props.getFormPost(postObject, post_url, "client");
  };

  render() {
    return (
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <Header {...this.props} currentPage={"sell-vouchers"} />
          <div className="layout-page">
            <Topmenu />
            <ToastContainer />
            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                <div className="row mb-3">
                  <div className="col-lg-10 col-md-6">
                    <h4 className="fw-bold">
                      {this.state.editID !== "" ? "Update" : "Add New"}{" "}
                      {moduleName}
                    </h4>
                  </div>
                  <div className="col-lg-2 col-md-6 text-end">
                    <Link to={modulePath}>
                      <button
                        type="button"
                        className="btn btn-outline-primary waves-effect"
                      >
                        Back
                      </button>
                    </Link>
                  </div>
                </div>
                <PostForm
                  {...this.props}
                  fields={this.state.clientdata}
                  editID={this.state.editID}
                  btndisabled={this.state.btndisabled}
                  onChange={this.fieldChange}
                  sateValChange={this.sateValChange}
                  onValid={this.handleSubmit}
                  error_msg={this.state.error_msg}
                  outletList={this.state.outletList}
                  companyCatList={this.state.companyCatList}
                  productTypeList={this.state.productTypeList}
                  settingsDetails={this.state.settingsDetails}
                  giftProductList={this.state.giftProductList}
                  onInvalid={() => {
                    console.log("Form invalid!");
                    setTimeout(function () {
                      if ($("#modulefrm .is-invalid").length > 0) {
                        $("html, body").animate(
                          {
                            scrollTop:
                              $(document)
                                .find("#modulefrm .is-invalid:first")
                                .offset().top - 100,
                          },
                          500
                        );
                      }
                    }, 500);
                  }}
                />
              </div>
              <Footer />
            </div>
          </div>
        </div>

        <div className="layout-overlay layout-menu-toggle"></div>
        <div className="drag-target"></div>
        <PageLoader pageloading={this.state.pageloading} />
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    formpost: state.formpost,
    detaildata: state.detaildata,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getFormPost: (formPayload, postUrl, authType) => {
      dispatch({ type: GET_FORMPOST, formPayload, postUrl, authType });
    },
    getDetailData: (datas) => {
      dispatch({ type: GET_DETAILDATA, datas });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Form);

function validationConfig(props) {
  const { assign_outlet, company_catid, product_name, sku, price, status } =
    props.fields;

  return {
    fields: [
      "assign_outlet",
      "company_catid",
      "product_name",
      "sku",
      "price",
      "status",
    ],

    validations: {
      assign_outlet: [[isMultiSelect, assign_outlet]],
      company_catid: [[isSingleSelect, company_catid]],
      product_name: [[isEmpty, product_name]],
      sku: [[isEmpty, sku]],
      price: [
        [isEmpty, price],
        [isValidPrice, price],
      ],
      status: [[isSingleSelect, status]],
    },
  };
}

class PostForm extends Component {
  handleChange(name, value) {
    this.props.onChange(name, value);
  }
  handleSelectChange(name, value) {
    this.props.onChange(name, value);
  }

  setContent(fieldName, value) {
    this.props.onChange(fieldName, value);
  }
  handleChangeCheck(name, value) {
    this.props.onChange(name, value);
  }
  myFilter(elm) {
    return elm != null && elm !== false && elm !== "";
  }

  async uplaodFiles(imageType) {
    this.props.sateValChange("btndisabled", true);
    showLoader("ImageDetails");
    showLoader("ImageContainer");
    var imagefile = document.querySelector("#" + imageType);
    const file = imagefile.files[0];
    var fileDetail = await uploadFile(file, "products", "client");
    if (fileDetail.status === "ok") {
      this.props.onChange(imageType, fileDetail.url);
    }
    hideLoader("ImageContainer");
    hideLoader("ImageDetails");
    $("#" + imageType).val("");
    this.props.sateValChange("btndisabled", false);
  }
  async removeImage(fileNamme, imageType, e) {
    e.preventDefault();
    showLoader("ImageDetails", "class");
    var fileDetail = await removeFile(fileNamme, "client");
    if (fileDetail.status === "ok") {
      hideLoader("ImageDetails", "class");
      this.props.onChange(imageType, "");
    }
  }
  copyLink(itemSlug) {
    toast.success("Link Copied Success", { autoClose: 1000 });
    navigator.clipboard.writeText(itemSlug);
  }

  handleChangeSocial(addIndex, e) {
    var updproductSocialanalytics = [];
    if (this.props.fields.productSocialanalytics !== "") {
      this.props.fields.productSocialanalytics.map((item, index) => {
        if (index === addIndex) {
          updproductSocialanalytics.push(e.target.value);
        } else {
          updproductSocialanalytics.push(item);
        }
        return "";
      });
    }
    this.props.onChange("productSocialanalytics", updproductSocialanalytics);
  }

  addsocialshare() {
    var socialanalytics = this.props.fields.productSocialanalytics;
    if (socialanalytics !== "") {
      socialanalytics.push("");
    } else {
      socialanalytics = [];
      socialanalytics.push("");
    }

    this.props.onChange("productSocialanalytics", socialanalytics);
  }
  removesocialshare(removeIndex) {
    var updsocialanalytics = [];
    if (this.props.fields.productSocialanalytics !== "") {
      this.props.fields.productSocialanalytics.map((item, index) => {
        if (index !== removeIndex) {
          updsocialanalytics.push(item);
        }
        return "";
      });
    }

    this.props.onChange("productSocialanalytics", updsocialanalytics);
  }

  render() {
    const { fields, onChange, onValid, onInvalid, $field, $validation } =
      this.props;
    let errMsgOutlet,
      errMsgCompanyCatId,
      errMsgProName,
      errMsgsku,
      errMsgStatus,
      errMsgProPrice;

    if ($validation.assign_outlet.error.reason !== undefined) {
      errMsgOutlet = $validation.assign_outlet.show && (
        <span className="error">{$validation.assign_outlet.error.reason}</span>
      );
    }
    if ($validation.company_catid.error.reason !== undefined) {
      errMsgCompanyCatId = $validation.company_catid.show && (
        <span className="error">{$validation.company_catid.error.reason}</span>
      );
    }
    if ($validation.product_name.error.reason !== undefined) {
      errMsgProName = $validation.product_name.show && (
        <span className="error">{$validation.product_name.error.reason}</span>
      );
    }
    if ($validation.sku.error.reason !== undefined) {
      errMsgsku = $validation.sku.show && (
        <span className="error">{$validation.sku.error.reason}</span>
      );
    }
    if ($validation.price.error.reason !== undefined) {
      errMsgProPrice = $validation.price.show && (
        <span className="error">{$validation.price.error.reason}</span>
      );
    }
    if ($validation.status.error.reason !== undefined) {
      errMsgStatus = $validation.status.show && (
        <span className="error">{$validation.status.error.reason}</span>
      );
    }

    return (
      <form className="card fv-plugins-bootstrap5" id="modulefrm">
        <div className="row g-3">
          <div
            className="accordion mt-3 accordion-header-primary"
            id="accordionStyle1"
          >
            <div className="accordion-item active">
              <h2 className="accordion-header">
                <button
                  type="button"
                  className="accordion-button"
                  data-bs-toggle="collapse"
                  data-bs-target="#accordionStyle1-3"
                  aria-expanded="true"
                >
                  General Info
                </button>
              </h2>
              <div
                id="accordionStyle1-3"
                className="accordion-collapse collapse show  mt-3"
                data-bs-parent="#accordionStyle1"
              >
                <div className="accordion-body">
                  <div className="row g-3">
                    <div
                      className={
                        errMsgOutlet !== "" &&
                        errMsgOutlet !== false &&
                        errMsgOutlet !== undefined
                          ? "col-md-6 error-select error"
                          : "col-md-6"
                      }
                    >
                      <div className="form-floating form-floating-outline custm-select-box mb-4">
                        <Select
                          value={fields.assign_outlet}
                          onChange={this.handleSelectChange.bind(
                            this,
                            "assign_outlet"
                          )}
                          placeholder={"Select Outlet"}
                          options={this.props.outletList}
                          isMulti
                          isClearable={true}
                        />
                        <label className="select-box-label">
                          Outlet<span className="error">*</span>
                        </label>
                        {errMsgOutlet}
                      </div>
                    </div>
                    <div
                      className={
                        errMsgCompanyCatId !== "" &&
                        errMsgCompanyCatId !== false &&
                        errMsgCompanyCatId !== undefined
                          ? "col-md-6 error-select error"
                          : "col-md-6"
                      }
                    >
                      <div className="form-floating form-floating-outline custm-select-box mb-4">
                        <Select
                          value={fields.company_catid}
                          onChange={this.handleSelectChange.bind(
                            this,
                            "company_catid"
                          )}
                          placeholder={"Select Category"}
                          options={this.props.companyCatList}
                          isClearable={true}
                        />
                        <label className="select-box-label">
                          Company Category<span className="error">*</span>
                        </label>
                        {errMsgCompanyCatId}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-floating form-floating-outline mb-4">
                        <input
                          type="text"
                          className={
                            errMsgProName !== "" &&
                            errMsgProName !== false &&
                            errMsgProName !== undefined
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          name="product_name"
                          value={fields.product_name}
                          {...$field("product_name", (e) =>
                            onChange("product_name", e.target.value)
                          )}
                        />
                        <label htmlFor="product_name">
                          Voucher Name <span className="error">*</span>
                        </label>
                        {errMsgProName}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating form-floating-outline mb-4">
                        <input
                          type="text"
                          className="form-control"
                          name="alias_name"
                          value={fields.alias_name}
                          {...$field("alias_name", (e) =>
                            onChange("alias_name", e.target.value)
                          )}
                        />
                        <label htmlFor="alias_name">Alias Name</label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating form-floating-outline mb-4">
                        <input
                          type="text"
                          className={
                            errMsgsku !== "" &&
                            errMsgsku !== false &&
                            errMsgsku !== undefined
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          name="sku"
                          value={fields.sku}
                          {...$field("sku", (e) =>
                            onChange("sku", e.target.value)
                          )}
                        />
                        <label htmlFor="sku">
                          SKU <span className="error">*</span>
                        </label>
                        {errMsgsku}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating form-floating-outline mb-4">
                        <input
                          type="text"
                          className={
                            errMsgProPrice !== "" &&
                            errMsgProPrice !== false &&
                            errMsgProPrice !== undefined
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          name="price"
                          value={fields.price}
                          {...$field("price", (e) =>
                            onChange("price", e.target.value)
                          )}
                        />
                        <label htmlFor="price">
                          Product Price / Offer Price{" "}
                          <span className="error">*</span>
                        </label>
                        {errMsgProPrice}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating form-floating-outline mb-4">
                        <input
                          type="text"
                          className="form-control"
                          name="product_cost"
                          value={fields.product_cost}
                          {...$field("product_cost", (e) =>
                            onChange("product_cost", e.target.value)
                          )}
                        />
                        <label htmlFor="product_cost">
                          Product Original Price
                        </label>
                      </div>
                    </div>

                    <div
                      className={
                        errMsgCompanyCatId !== "" &&
                        errMsgCompanyCatId !== false &&
                        errMsgCompanyCatId !== undefined
                          ? "col-md-6 error-select error"
                          : "col-md-6"
                      }
                    >
                      <div className="form-floating form-floating-outline custm-select-box mb-4">
                        <Select
                          value={fields.voucher_type}
                          onChange={this.handleSelectChange.bind(
                            this,
                            "voucher_type"
                          )}
                          placeholder={"Select Voucher Type"}
                          options={[
                            { value: "F", label: "Product Voucher" },
                            { value: "C", label: "Cash Voucher" },
                          ]}
                          isClearable={true}
                        />
                        <label className="select-box-label">
                          Voucher Type<span className="error">*</span>
                        </label>
                        {errMsgCompanyCatId}
                      </div>
                    </div>
                    {fields.voucher_type?.value === "C" && (
                      <>
                        {this.props.settingsDetails?.typeofCreditRedeem ===
                          "1" && (
                          <div className="col-md-6">
                            <div className="form-floating form-floating-outline mb-4">
                              <input
                                type="text"
                                className="form-control"
                                name="voucher_bonus_amount"
                                value={fields.voucher_bonus_amount}
                                {...$field("voucher_bonus_amount", (e) =>
                                  onChange(
                                    "voucher_bonus_amount",
                                    e.target.value
                                  )
                                )}
                              />
                              <label htmlFor="voucher_bonus_amount">
                                Bonus Amount
                              </label>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                    <div className="col-md-6">
                      <div className="form-floating form-floating-outline mb-4 custm-date-box">
                        <DatePicker
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          className="form-control"
                          selected={fields.voucher_expity_date}
                          minDate={new Date()}
                          dateFormat="dd/MM/yyyy"
                          onChange={this.handleChange.bind(
                            this,
                            "voucher_expity_date"
                          )}
                        />
                        <label className="select-box-label">
                          Voucher Expiry Date
                        </label>
                      </div>
                    </div>
                    
                      <div className="col-md-6">
                        <div className="form-floating form-floating-outline custm-select-box mb-4">
                          <Select
                            value={fields.giftProduct}
                            onChange={this.handleSelectChange.bind(
                              this,
                              "giftProduct"
                            )}
                            placeholder={"Select Gift Voucher"}
                            options={this.props.giftProductList}
                            isClearable={true}
                          />
                          <label className="select-box-label">
                            Gift Voucher
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row g-3">
                    <div className="col-md-3">
                      <div className="form-floating form-floating-outline mb-4">
                        <Switch
                          onChange={this.handleChangeCheck.bind(
                            this,
                            "buy_refer"
                          )}
                          checked={fields.buy_refer}
                          onColor="#666cff"
                          className="react-switch"
                          onHandleColor="#bdbfff"
                          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                          activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                        />
                        <span>Applicable Influencer Commission</span>
                      </div>
                    </div>
                    {fields.buy_refer === true && (
                      <div className="col-md-2">
                        <div className="form-floating form-floating-outline mb-4">
                          <input
                            type="text"
                            className="form-control"
                            name="influencer_commission"
                            value={fields.influencer_commission}
                            {...$field("influencer_commission", (e) =>
                              onChange("influencer_commission", e.target.value)
                            )}
                          />
                          <label htmlFor="influencer_commission">
                            Commission Percentage
                          </label>
                        </div>
                      </div>
                    )}
                    <div className="col-md-2">
                      <div className="form-floating form-floating-outline mb-4">
                        <Switch
                          onChange={this.handleChangeCheck.bind(
                            this,
                            "recomentedVoucher"
                          )}
                          checked={fields.recomentedVoucher}
                          onColor="#666cff"
                          className="react-switch"
                          onHandleColor="#bdbfff"
                          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                          activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                        />
                        <span>Highlight Voucher</span>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="form-floating form-floating-outline mb-4">
                        <Switch
                          onChange={this.handleChangeCheck.bind(
                            this,
                            "productWelcomeVoucher"
                          )}
                          checked={fields.productWelcomeVoucher}
                          onColor="#666cff"
                          className="react-switch"
                          onHandleColor="#bdbfff"
                          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                          activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                        />
                        <span>Welcome Bonus</span>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="form-floating form-floating-outline mb-4">
                        <Switch
                          onChange={this.handleChangeCheck.bind(
                            this,
                            "applyFreeVoucher"
                          )}
                          checked={fields.applyFreeVoucher}
                          onColor="#666cff"
                          className="react-switch"
                          onHandleColor="#bdbfff"
                          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                          activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                        />
                        <span>Free Voucher</span>
                      </div>
                    </div>
                    {fields.applyFreeVoucher === true && (
                      <>
                        <div className="col-md-2">
                          <div className="form-floating form-floating-outline mb-4">
                            <input
                              type="text"
                              className="form-control"
                              name="influencer_commission"
                              value={fields.freevoucherQuantity}
                              {...$field("freevoucherQuantity", (e) =>
                                onChange("freevoucherQuantity", e.target.value)
                              )}
                            />
                            <label htmlFor="freevoucherQuantity">
                              Maximum Free Voucher
                            </label>
                          </div>
                        </div>
                      </>
                    )}
                    <div className="col-md-2">
                      <div className="form-floating form-floating-outline mb-4">
                        <Switch
                          onChange={this.handleChangeCheck.bind(
                            this,
                            "product_hide"
                          )}
                          checked={fields.product_hide}
                          onColor="#666cff"
                          className="react-switch"
                          onHandleColor="#bdbfff"
                          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                          activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                        />
                        <span>Hide From Public</span>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <label>Short Description</label>
                      <Editor
                        setContent={this.setContent.bind(
                          this,
                          "short_description"
                        )}
                        data={fields.short_description}
                      />
                    </div>
                    <div className="col-md-12">
                      <label>Long Description</label>
                      <Editor
                        setContent={this.setContent.bind(
                          this,
                          "long_description"
                        )}
                        data={fields.long_description}
                      />
                    </div>
                    <div className="col-md-12">
                      <label>Terms and Conditions</label>
                      <Editor
                        setContent={this.setContent.bind(
                          this,
                          "termsandconditions"
                        )}
                        data={fields.termsandconditions}
                      />
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating form-floating-outline mt-4">
                        <input
                          type="text"
                          className="form-control"
                          name="influencer_commission"
                          value={fields.product_sequence}
                          {...$field("product_sequence", (e) =>
                            onChange("product_sequence", e.target.value)
                          )}
                        />
                        <label htmlFor="product_sequence">Sorting</label>
                      </div>
                    </div>
                    <div
                      className={
                        errMsgStatus !== "" &&
                        errMsgStatus !== false &&
                        errMsgStatus !== undefined
                          ? "col-md-6 error-select error"
                          : "col-md-6"
                      }
                    >
                      <br></br>
                      <div className="form-floating form-floating-outline custm-select-box mb-4">
                        <Select
                          value={fields.status}
                          onChange={this.handleSelectChange.bind(
                            this,
                            "status"
                          )}
                          placeholder="Select Status"
                          options={[
                            { value: "A", label: "Active" },
                            { value: "I", label: "In Active" },
                          ]}
                          isClearable={true}
                        />
                        <label className="select-box-label">
                          Status<span className="error">*</span>
                        </label>
                        {errMsgStatus}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating form-floating-outline mb-4">
                        <div className="mb-3" id="ImageContainer">
                          <label htmlFor="formFile" className="form-label">
                            Thumbnail Image
                          </label>
                          <input
                            className="form-control"
                            type="file"
                            id="thumbnail"
                            onChange={(event) => {
                              this.uplaodFiles("thumbnail", event);
                              return false;
                            }}
                          />
                        </div>
                      </div>
                      {fields.thumbnail !== "" && (
                        <div className="dz-preview dz-processing dz-image-preview dz-success dz-complete">
                          <div className="dz-details" id="ImageDetails">
                            <div className="dz-thumbnail">
                              <img alt="" src={fields.thumbnail} />
                            </div>
                          </div>
                          <a
                            className="dz-remove"
                            href="/"
                            onClick={this.removeImage.bind(
                              this,
                              fields.thumbnail,
                              "thumbnail"
                            )}
                          >
                            Remove file
                          </a>
                        </div>
                      )}
                    </div>

                    
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  type="button"
                  className="accordion-button"
                  data-bs-toggle="collapse"
                  data-bs-target="#accordionStyle1-5"
                  aria-expanded="true"
                >
                  Generate Unique Links
                </button>
              </h2>
              <div
                id="accordionStyle1-5"
                className="accordion-collapse collapse  mt-3"
                data-bs-parent="#accordionStyle5"
              >
                <div className="accordion-body">
                  <div className="row g-3">
                    {fields.socialanalytics.length > 0 &&
                      fields.socialanalytics.map((item, index) => {
                        var slugName =
                          this.props.editID !== ""
                            ? this.props.fields.slug
                            : generateSlug(fields.product_name);
                        var displaySlug =
                          frontURL +
                          "buy-vouchers/" +
                          slugName +
                          "/share/" +
                          item;
                        return (
                          <div className="row g-3" key={index}>
                            <div className="col-md-3">
                              <h5 className="mb-0 mt-3">{item}</h5>
                            </div>
                            <div className="col-md-6">
                              <div className="mb-4">
                                <div className="input-group input-group-merge">
                                  <div className="form-floating form-floating-outline">
                                    <input
                                      type="text"
                                      className="form-control"
                                      id={`link_${index}`}
                                      readOnly
                                      value={displaySlug}
                                      aria-describedby={`link_${index}`}
                                    />
                                    <label htmlFor={`link_${index}`}>
                                      Link
                                    </label>
                                  </div>
                                  <span
                                    className="input-group-text cursor-pointer"
                                    onClick={this.copyLink.bind(
                                      this,
                                      displaySlug
                                    )}
                                  >
                                    <i className="mdi mdi-content-copy"></i>
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-2">
                              <div className="mt-3">
                                {(fields.productSocialanalytics === "" ||
                                  Object.keys(fields.productSocialanalytics)
                                    .length === 0) &&
                                  parseInt(index) + 1 ===
                                    fields.socialanalytics.length && (
                                    <span
                                      className="act-plus-icon"
                                      onClick={this.addsocialshare.bind(this)}
                                    >
                                      <i className="mdi mdi-plus-circle-outline"></i>
                                    </span>
                                  )}
                              </div>
                            </div>
                          </div>
                        );
                      })}

                    {fields.productSocialanalytics !== "" &&
                      fields.productSocialanalytics.map((item, index) => {
                        return (
                          <div className="row g-3" key={index}>
                            <div className="col-md-3">
                              <div className="mb-4">
                                <div className="input-group input-group-merge">
                                  <div className="form-floating form-floating-outline">
                                    <input
                                      type="text"
                                      className="form-control"
                                      id={`link_${index}`}
                                      value={item}
                                      onChange={this.handleChangeSocial.bind(
                                        this,
                                        index
                                      )}
                                      aria-describedby={`link_${index}`}
                                    />
                                    <label htmlFor={`link_${index}`}>
                                      Name
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="mb-4">
                                <div className="input-group input-group-merge">
                                  <div className="form-floating form-floating-outline">
                                    <input
                                      type="text"
                                      className="form-control"
                                      id={`link_${index}`}
                                      readOnly
                                      value={
                                        item !== ""
                                          ? frontURL +
                                            "buy-vouchers/" +
                                            this.props.fields.slug +
                                            "/share/" +
                                            item
                                          : ""
                                      }
                                      aria-describedby={`link_${index}`}
                                    />
                                    <label htmlFor={`link_${index}`}>
                                      Link
                                    </label>
                                  </div>
                                  <span
                                    className="input-group-text cursor-pointer"
                                    onClick={this.copyLink.bind(
                                      this,
                                      frontURL +
                                        "buy-vouchers/" +
                                        this.props.fields.slug +
                                        "/share/" +
                                        item
                                    )}
                                  >
                                    <i className="mdi mdi-content-copy"></i>
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-2">
                              <div className="mt-3">
                                {fields.productSocialanalytics.length > 1 && (
                                  <span
                                    className="act-minus-icon"
                                    onClick={this.removesocialshare.bind(
                                      this,
                                      index
                                    )}
                                  >
                                    <i className="mdi mdi-minus-circle-outline"></i>
                                  </span>
                                )}
                                {fields.productSocialanalytics.length - 1 ===
                                  index && (
                                  <span
                                    className="act-plus-icon"
                                    onClick={this.addsocialshare.bind(this)}
                                  >
                                    <i className="mdi mdi-plus-circle-outline"></i>
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="pt-1 pb-4 pr-2 text-end"
            style={{ paddingRight: "20px" }}
          >
            <button
              type="button"
              disabled={this.props.btndisabled}
              className="btn btn-primary me-sm-3 me-1 waves-effect waves-light submit_frm"
              onClick={(e) => {
                e.preventDefault();
                if (!this.props.btndisabled) {
                  this.props.$submit(onValid, onInvalid);
                }
              }}
            >
              Submit
            </button>
            <Link to={modulePath}>
              <button
                type="reset"
                className="btn btn-label-secondary waves-effect"
              >
                Cancel
              </button>
            </Link>
          </div>
        </div>
      </form>
    );
  }
}
PostForm = validated(validationConfig)(PostForm);
